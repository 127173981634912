import PropTypes from 'prop-types';
import bem from 'b_';
import cn from 'classnames';
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import FuseSnackbar from './Components/FuseControls/FuseSnackbar';
import SnackStore from './stores/SnackStore';
import { IS_IOS } from './env';
import clientConfig from './clientConfig';
import './RootPage.css';
import { setTheme, THEME_VALUES } from './themesStylesConfig';
import { isFuse5Enabled } from './fuseFeatureFlag';
import LS, { LS_KEY } from './LocalStorage';

const block = bem.lock('RootPage');

function updateHTMLClassName(appName) {
    // ios modificator prevent page bouncing on scrolling
    document.documentElement.className = block('HTML', { [appName]: true, ios: IS_IOS });
}

export default class RootPage extends Component {
    static propTypes = {
        appName: PropTypes.oneOf(['browser', 'console', 'superduper']).isRequired,
    };

    constructor(...args) {
        super(...args);
        this.fuse5className = '';
        clientConfig.updateConfig();
    }

    componentDidMount() {
        updateHTMLClassName(this.props.appName);
        if (isFuse5Enabled()) {
            const themeSchema = LS.get(LS_KEY.themeColorScheme, THEME_VALUES.LIGHT);
            setTheme(themeSchema || THEME_VALUES.LIGHT);
            this.fuse5className = 'fuse5-ui';
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.appName !== prevProps.appName) {
            updateHTMLClassName(this.props.appName);
        }
    }

    render() {
        return <div
            key={this.props.appName}
            className={cn(block(), this.fuse5className)}
        >
            <div className={block('Wrapper')}>
                {this.props.children}
            </div>
            <Provider snack={new SnackStore()}>
                <FuseSnackbar />
            </Provider>
        </div>;
    }
}
